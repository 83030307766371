import axios from 'axios';
import toast from 'react-hot-toast';
import { API_URL, geneateKeywoardGoogle } from '../../page/constants/index';

export const analyseArticle = async (formData, session) => {
  try {
    console.log("keyword:", formData['keyword']);
    console.log("language:", formData['language']);

    const responseUrlList = await axios.get(
      `${API_URL}api/scrap/creat/?&key=${formData['keyword']}&country=2818&lang=${formData['language']}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `token ${session}`,
        },
      }
    );

    console.log('responseUrlList:', responseUrlList);

    for (let i = 0; i < responseUrlList.data['urls'].length; i++) {
      const urlResponseKey = await axios.get(
        `${geneateKeywoardGoogle}?&id=${responseUrlList.data['idKeywoard']}&url=${responseUrlList.data['urls'][i]}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${session}`,
          },
        }
      );

      for (let j = 0; j < responseUrlList.data['urls'].length; j++) {
        try {
          const urlResponse = await axios.get(
            `${API_URL}api/scrap/url/?format=json&key=${j}&id=${responseUrlList.data['id']}`
          );
          
          console.log(urlResponse);
        } catch (error) {
          console.log(error);
        }
      }

      const full_Response = await axios.get(
        `${API_URL}api/scrap/make_full_data/?format=json&id=${responseUrlList.data['id']}`
      );
      console.log("full_Response:", full_Response);
      console.log("urlResponseKey:", urlResponseKey);
    }
    
    //toast.success('تم إرسال البيانات بنجاح');
    return responseUrlList.data['id']
  } catch (error) {
    console.error('Error sending data to backend:', error.response?.data?.error);
    toast.error(error?.response?.data?.error || 'حدث خطأ في إرسال البيانات');
  }
};
