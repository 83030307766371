import React, { useState, useEffect } from 'react';
import { Button, Form, Container, Modal, Spinner, Dropdown, DropdownButton } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { ImageUploadMultip,GeneratArticleByModelAuto,geneateKeywoardGoogle,API_URL } from "../../page/constants/index";
import toast, { Toaster } from 'react-hot-toast';
import '../../style/ModelPage.css';
import { connect } from 'react-redux';
import {analyseArticle} from "../../lib/analyse/analyseArticle"
import KeywordArrayModal from "./KeywordArrayModal"
const ImageUpload = ({ onImagesUploaded,imageUrlsArray }) => {
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setImages(files);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    images.forEach((image) => {
      formData.append('images', image); // Use the same key for all files
    });

    setIsLoading(true);
    try {
      const response = await axios.post(ImageUploadMultip, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      onImagesUploaded(response.data.image_urls);
      console.log('Uploaded Image URLs:', response.data.image_urls);
      toast.success('تم رفع الصور بنجاح');
    } catch (error) {
      console.error('Error uploading images:', error);
      toast.error('حدث خطأ في رفع الصور');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form.Group controlId="form-images">
      <Form.Label className="text-light">الصور</Form.Label>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Form.Control
          type="file"
          accept=".png, .jpg, .jpeg"
          multiple
          onChange={handleFileChange}
          className="bg-dark text-light"
        />
        <Button variant="primary" onClick={handleUpload} className="mt-2" disabled={isLoading}>
          {isLoading ? (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          ) : (
            `تحميل الصور (${imageUrlsArray ? imageUrlsArray.length : 0})`
          )}
        </Button>
      </div>
      <Toaster />
    </Form.Group>
  );
};

const DashboardRow = ({ ModelOption = {}, LangOption = [],session ,WaitingModel, setStateGeneration,setmodelAutoID,modelAutoID,setAllArticle}) => {
  const [formData, setFormData] = useState({
    model: '',
    images: [],
    videos: '',
    sources: '',
    tags: '',
    titles: '',
    language: '',
    keyword: '',
    article: ''
  });
  const [showModal, setShowModal] = useState({ sources: false, tags: false, videos: false });
  const [modalContent, setModalContent] = useState({ sources: '', tags: '', videos: '' });
  const [modalLanguage, setModalLanguage] = useState('العربية');
  const [videoPer, setVideoPer] = useState('');

  const [modelAutoData, setmodelAutoData] = useState();
  const [rangeValues, setRangeValues] = useState({ sources: { from: '', to: '' }, tags: { from: '', to: '' } });
  const [showKeywordModal, setShowKeywordModal] = useState(false);
  const [keywordArray, setKeywordArray] = useState([]);
  const [modelAutoIDArray,setmodelAutoIDArray] =useState([]);
  const [imageUrlsArray,setimageUrlsArray] = useState([]);
  const handleInputChange = (label, value) => {
    setFormData({
      ...formData,
      [label]: value,
    });
  };

  const handleRangeChange = (label, field, value) => {
    setFormData({
      ...formData,
      [label]: {
        ...formData[label],
        [field]: value,
      },
    });
  };

  const handleModalSave = (label) => {
    if (modalContent[label]) {
      setFormData({
        ...formData,
        [label]: modalContent[label].split('\n'),
      });
    } else {
      setFormData({
        ...formData,
        [label]: [],
      });
    }
    setShowModal({ ...showModal, [label]: false });
  };

  const handleLog = async () => {
    setStateGeneration(0);
    console.log("keywordArray", keywordArray);

    const results = [];

    WaitingModel(true);
    
    try {
        const promises = keywordArray.map((keyword) => {
            const updatedFormData = { ...formData, keyword }; // Update keyword in formData

            const loggedData = {
                model: updatedFormData['model'],
                images: updatedFormData['images'],
                videos: updatedFormData['videos'],
                sources: updatedFormData['sources'],
                tags: updatedFormData['tags'],
                titles: "1",
                language: updatedFormData['language'],
                keyword: updatedFormData['keyword'],
                article: updatedFormData['article'],
                images: imageUrlsArray,
            };

            return analyseArticle(updatedFormData, session).then((idArticle) => {
                
                return {
                    keyword,
                    idArticle,
                    loggedData
                };
            });
        });

        const allResults = await Promise.all(promises);
        console.log("All results:", allResults);
        results.push(...allResults);
        let AutoID = []
        for (const result of allResults) {
            const { idArticle, loggedData } = result;
            loggedData.article_model_id = idArticle;
            setmodelAutoData(loggedData);

            try {
                setStateGeneration(1);
                let response = await axios.post(GeneratArticleByModelAuto + "?type=preparation", loggedData, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `token ${session}`,
                    },
                });
              
                //setmodelAutoID(response?.data?.id);
                AutoID.push(response?.data?.id)
            } catch (error) {
               
                const errorMessage = error?.response?.data?.error || "حدث خطأ في مرحلة التحليل";
                toast.error(errorMessage);
            }
        }
        console.log("====================================")
        console.log("AutoID",AutoID)
        console.log("====================================")
        setmodelAutoIDArray(AutoID)
    } catch (error) {
      WaitingModel(false);
        console.error("Error processing keywords:", error);
    } 

    return results;
};


  const handleImagesUploaded = (imageUrls) => {
    setimageUrlsArray(imageUrls)
    
   
  };

  useEffect(() => {
    if (modelAutoIDArray && modelAutoIDArray.length > 0) {
        console.log("creating article state");
        setStateGeneration(2);

        const fetchData = async () => {
            try {
                const allResponses = [];

                for (const modelAutoID of modelAutoIDArray) {
                    // Array to store promises for each modelAutoID
                    const requests = [];

                    // Loop to create multiple requests
                    let intcountArticle = Math.floor(Number(modelAutoData.article));
                    let countArticle = intcountArticle > 0 ? intcountArticle - 1 : 0;
                    console.log("countArticle", countArticle);

                    for (let i = 0; i <= countArticle; i++) {
                        const request = axios.get(
                            `${GeneratArticleByModelAuto}?id=${modelAutoID}`,
                            {
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: `token ${session}`,
                                },
                            }
                        );
                        requests.push(request);
                    }

                    // Wait for all requests for this modelAutoID to finish
                    const responses = await Promise.all(requests);

                    responses.forEach((response, index) => {
                        allResponses.push(response.data); // Add each response to the array
                    });
                }

                console.log("allResponses", allResponses);
                setAllArticle(allResponses);
            } catch (error) {
                console.error("An error occurred:", error);
            } finally {
                WaitingModel(false);
            }
        };

        fetchData(); // Call the async function
    }
}, [modelAutoIDArray]);


  

  return (
    <Container className="p-4" style={{ backgroundColor: '#212429', borderRadius: '10px', paddingTop: "0px !important", paddingBottom: "0px !important" }}>
      <div className="row align-items-center mb-4 container-field-auto" style={{ width: "85%", flexWrap: "nowrap" }}>
        <div className="col-md-3 mb-3">
          <ImageUpload onImagesUploaded={handleImagesUploaded} imageUrlsArray={imageUrlsArray} />
        </div>

        <div className="col-md-2 mb-3">
        <Form.Group controlId="form-model">
        <Form.Label className="text-light">النموذج</Form.Label>
        <Form.Select
          value={formData['model']}
          onChange={(e) => handleInputChange('model', e.target.value)}
          className="bg-dark text-light"
        >
          <option value="">اختر النموذج</option>
          {ModelOption && Array.isArray(ModelOption) && ModelOption.length > 0 ? (
            ModelOption.map((template) => (
                <option key={template.template_id} value={template.template_id}>
                    {template.template_name}
                </option>
            ))
        ) : (
            <option disabled>لا توجد نماذج متاحة</option>
        )}
        </Form.Select>
      </Form.Group>
        </div>

        <div className="col-md-2 mb-3" style={{ width: "100px " }}>
          <Form.Group controlId="form-language">
            <Form.Label className="text-light">اللغة</Form.Label>
            <Form.Select
              value={formData['language']}
              onChange={(e) => handleInputChange('language', e.target.value)}
              className="bg-dark text-light"
            >
              <option value=""> اللغة</option>
              <option value="ar">العربية</option>
              <option value="en">الانجليزية</option>
           
            </Form.Select>
          </Form.Group>
        </div>

    
        <div className="col-md-3 mb-3" style={{ width: "120px" }}>
   
        <Form.Group controlId="form-model">
        <Form.Label className="text-light">المواقع</Form.Label>
        <Form.Select
          value={formData['model']}
          onChange={(e) => handleInputChange('model', e.target.value)}
          className="bg-dark text-light"
        >
          <option value="10">10</option>
          <option value="8">8</option>
          <option value="6">6</option>
          <option value="4">4</option>
          <option value="3">3</option>
        </Form.Select>
      </Form.Group>
        </div>
        <div className="col-md-3 mb-3" style={{ width: "120px" }}>
   
        <Form.Group controlId="form-model">
        <Form.Label className="text-light">المواقع</Form.Label>
        <Form.Select
          value={formData['model']}
          onChange={(e) => handleInputChange('model', e.target.value)}
          className="bg-dark text-light"
        >
          <option value="gpt-3">gpt-3</option>
          <option value="gpt-4">gpt-4</option>
        
        </Form.Select>
      </Form.Group>
        </div>
        <div className="col-md-3 mb-3" style={{ width: "84px" }}>
          <Form.Group controlId="form-article">
            <Form.Label className="text-light">المقالات</Form.Label>
            <Form.Control
              type="number"
              max="10"
              value={formData['article'] || ''}
              onChange={(e) => handleInputChange('article', e.target.value)}
              className="bg-dark text-light"
            />
          </Form.Group>
        </div>
      </div>
      <div className="row align-items-center mb-4 " style={{ width: "54%", justifyContent: "end", paddingRight: "5px" }}>
        <div className="col-md-6 mb-3 d-flex" style={{ width: "100%", display: "flex !important", justifyContent: "flex-end !important", gap: "10px", marginTop: "29px" }}>
        <Button variant="secondary" onClick={()=>{setShowKeywordModal(true)}} className="bg-dark text-light">
              الكلمات المفتاحية ({keywordArray.length})
            </Button>
          <DropdownButton id="dropdown-basic-button" title="إعدادات إضافية" variant="secondary">
            <Dropdown.Item onClick={() => setShowModal({ ...showModal, videos: true })}>الفديوهات</Dropdown.Item>
            <Dropdown.Item onClick={() => setShowModal({ ...showModal, sources: true })}>المصادر</Dropdown.Item>
            <Dropdown.Item onClick={() => setShowModal({ ...showModal, tags: true })}>التاجات</Dropdown.Item>
          </DropdownButton>
          
          <Button variant="primary" onClick={handleLog} className="bg-dark text-light" style={{ height: "70%" }}>
            سجل البيانات
          </Button>
          
        </div>
      </div>

      {/* Modal for sources, tags, and videos */}
      {['sources', 'tags', 'videos'].map((label) => (
        <Modal key={label} show={showModal[label]} onHide={() => setShowModal({ ...showModal, [label]: false })}>
          <Modal.Header  className="bg-dark text-light">
            <Modal.Title>{label}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-dark text-light">
            <Form.Group controlId={`modal-${label}`}>
              <Form.Label className="text-light">أدخل البيانات (سطر لكل عنصر)</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                value={modalContent[label] || ''}
                onChange={(e) => setModalContent({ ...modalContent, [label]: e.target.value })}
                className="bg-dark text-light"
              />
            </Form.Group>
            {label === 'videos' && (
              <Form.Group controlId="modal-video-per" className="mt-3">
                <Form.Label className="text-light">فديو لكل</Form.Label>
                <Form.Control
                  type="number"
                  value={videoPer}
                  onChange={(e) => setVideoPer(e.target.value)}
                  className="bg-dark text-light"
                />
              </Form.Group>
            )}
            {['sources', 'tags'].includes(label) && (
              <div className="d-flex gap-2 mt-3">
                <Form.Label className="text-light">من</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="من"
                  value={rangeValues[label].from}
                  onChange={(e) => setRangeValues({
                    ...rangeValues,
                    [label]: { ...rangeValues[label], from: e.target.value },
                  })}
                  className="bg-dark text-light"
                />
                <Form.Label className="text-light">إلى</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="إلى"
                  value={rangeValues[label].to}
                  onChange={(e) => setRangeValues({
                    ...rangeValues,
                    [label]: { ...rangeValues[label], to: e.target.value },
                  })}
                  className="bg-dark text-light"
                />
              </div>
            )}
            {label === 'sources' && (
              <Form.Group controlId="modal-language" className="mt-3">
                <Form.Label className="text-light">اللغة</Form.Label>
                <Form.Select
                  value={modalLanguage}
                  onChange={(e) => setModalLanguage(e.target.value)}
                  className="bg-dark text-light"
                >
                   <option value="ar">العربية</option>
                <option value="en">الانجليزية</option>
           
       
                </Form.Select>
              </Form.Group>
            )}
          </Modal.Body>
          <Modal.Footer className="bg-dark text-light">
            <Button variant="secondary" onClick={() => setShowModal({ ...showModal, [label]: false })} className="bg-dark text-light">
              إغلاق
            </Button>
            <Button variant="primary" onClick={() => handleModalSave(label)} className="bg-dark text-light">
              حفظ
            </Button>
          </Modal.Footer>
        </Modal>
      ))}
      <KeywordArrayModal
        show={showKeywordModal}
        onClose={() => setShowKeywordModal(false)}
        setKeywordArray={setKeywordArray}
      />
    </Container>
  );
};
const mapStateToProps = (state) => ({
  session: state.session,
});

export default connect(mapStateToProps)(DashboardRow);