import React, { useState,useEffect } from 'react';
import { Header } from "../composant/ModelPage/index";
import DashboardRow from "../composant/ModelPageAuto/DashboardRow";
import ArticleTable from "../composant/ModelPageAuto/ArticleTable"
import { Modal, Spinner } from "react-bootstrap";
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { connect } from 'react-redux';
import { ModelSave } from "./constants/index";
import "./App.css";

const ModelOptionDict = { "model 1": 16, "model 2": 85, "model 3": 85 };
const LangOption = ['العربية', 'الإنجليزية', 'الفرنسية'];
const stateArray = ['مرحلة التحليل', 'توليد الداتا', 'كتابة المقالات'];


function AutoModelPage({session}) {
  const [showModal, setShowModal] = useState(false); // Modal visibility
  const [state, setState] = useState(0); // Current state
  const [ShowArticle, setShowArticle] = useState(0);
  const [ArticleData, setArticleData] = useState(0);
  const [modelAutoID, setmodelAutoID] = useState();
  const [AllArticle, setAllArticle] = useState();
  const [Templates,setTemplates] = useState();
  
  const handleClose = () => {
    setShowModal(false);
    setState(0); // Reset state when closing
  };

  useEffect(() => {
    
      // Fetch templates when the modal is shown
      console.log("session",session)
      axios
        .get(ModelSave, {
          headers: {
            Authorization: `token ${session}`,
          },
        })
        .then((response) => {
          setTemplates(response.data);
        })
        .catch((error) => {
          toast.error("خطأ في تحميل النماذج");
          console.log("error",error)
          
        });
    
  }, []);
 

  return (
    <div className="home" style={{ flexDirection: "column", backgroundColor: "#121212", color: "#ffffff" }}>
      <Header />
      <DashboardRow ModelOption={Templates} LangOption={LangOption} setStateGeneration={setState} WaitingModel={setShowModal} setmodelAutoID={setmodelAutoID} modelAutoID={modelAutoID} setAllArticle={setAllArticle}/>
      {AllArticle&&
      <ArticleTable allResponses= {AllArticle}/>}
      
      

      {/* Modal */}
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        backdrop="static" // Prevent closing when clicking outside
      >
        
       
        <Modal.Body style={{ backgroundColor: "#1e1e1e", color: "#ffffff" }}>
          <div className="text-center">
            {/* Loader Spinner */}
            <Spinner animation="border" role="status" className="mb-3" style={{ color: "#ffffff" }}>
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <p>{stateArray[state]}</p>
          </div>
        </Modal.Body>
        
      </Modal>
    </div>
  );
}


const mapStateToProps = (state) => ({

  session: state.session,
});
export default connect(mapStateToProps)(AutoModelPage);