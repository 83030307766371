import React, { useState } from "react";
import { Table, Button, Pagination } from "react-bootstrap";

function ArticleTable({ allResponses = [] }) {
  console.log("allResponses inside", allResponses);

  const [currentPage, setCurrentPage] = useState(1); // Manage current page
  const itemsPerPage = 7; // Number of items per page

  // Calculate the total number of pages
  const totalPages = Math.ceil(allResponses.length / itemsPerPage);

  // Get the current items to display
  const currentItems = allResponses.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div
      className="container mt-4"
      style={{
        backgroundColor: "#121212",
        color: "#ffffff",
        padding: "20px",
        borderRadius: "8px",
        display:"flex",
        flexDirection:"column"
      }}
    >
      {allResponses.length === 0 ? (
        <p className="text-center" style={{ color: "#ffffff" }}>
          لا توجد ردود متاحة
        </p>
      ) : (
        <>
          <Table striped bordered hover variant="dark" className="text-center">
            <thead>
              <tr>
                <th>رقم الرد</th>
                <th>عدد العناوين</th>
                <th>عنوان المقالة</th>
                <th>الانتقال إلى المقالة</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((response, index) => (
                <tr key={index}>
                  <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                  <td>{response.headers}</td>
                  <td>{response.head_one}</td>
                  <td>
                    <Button
                      variant="primary"
                      onClick={() =>
                        window.open(`/write/${response.htmlArticle}/load`, "_blank")
                      }
                    >
                      عرض المقالة
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          {/* Pagination */}
          <Pagination className="justify-content-center">
            <Pagination.First
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            />
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {Array.from({ length: totalPages }, (_, i) => (
              <Pagination.Item
                key={i + 1}
                active={i + 1 === currentPage}
                onClick={() => handlePageChange(i + 1)}
              >
                {i + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </>
      )}
    </div>
  );
}

export default ArticleTable;
