import React, { useState, useEffect } from 'react';
import {Header} from "../composant/ModelPage/index";
import Modeltable from "../composant/ModelPage/Modeltable"

import "./App.css";

function ModelPage() {
    const [DataArticle, setDataArticle] = useState();
  return (
    <div className="home" style={{flexDirection: "column"}}>
      <Header />
      <Modeltable  rowNumber={20}/>
      {/* Additional content can go here */}
    </div>
  );
}

export default ModelPage;
