import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const KeywordArrayModal = ({ show, onClose, setKeywordArray }) => {
  const [inputContent, setInputContent] = useState('');
  const [lineCount, setLineCount] = useState(0);

  // Update line count whenever the input changes
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputContent(value);
    const lines = value.split('\n').filter((line) => line.trim() !== ''); // Filter empty lines
    setLineCount(lines.length);
  };

  const handleSave = () => {
    const keywords = inputContent.split('\n').filter((line) => line.trim() !== ''); // Filter empty lines
    setKeywordArray(keywords);
    onClose();
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header  className="bg-dark text-light">
        <Modal.Title>إدخال الكلمات المفتاحية</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-dark text-light">
        <Form.Group controlId="form-keyword-array">
          <Form.Label className="text-light">أدخل الكلمات المفتاحية (سطر لكل كلمة)</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            value={inputContent}
            onChange={handleInputChange}
            className="bg-dark text-light"
          />
        </Form.Group>
        <div className="mt-3">
          <strong>عدد الكلمات:</strong> {lineCount}
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-dark text-light">
        <Button variant="secondary" onClick={onClose} className="bg-dark text-light">
          إغلاق
        </Button>
        <Button variant="primary" onClick={handleSave} className="bg-dark text-light">
          حفظ
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default KeywordArrayModal;
