import React from 'react';
import { Navbar, Button } from 'react-bootstrap';

export function Header() {
  return (
    <Navbar expand="lg" style={{  height: '55px', width: '100%' }}>
      <div className="ml-auto d-flex flex-row" style={{ flexDirection: 'row',    display: "flex" }}>
      <Button style={{padding:"5px 25px 5px 25px"}} className ="dashboard-option"variant="outline-light" href="/model">
          النماذج
        </Button>
        <Button  style={{padding:"5px 25px 5px 25px"}} className="dashboard-option" variant="outline-light" href="/model/auto" >
          ادخال الي
        </Button>
        <Button style={{padding:"5px 25px 5px 25px"}}className="dashboard-option" variant="outline-light" href="#manual-input" disabled={true}>
          ادخال يدوي
        </Button>
        
       
      </div>
    </Navbar>
  );
}


